import {
    FileInput,
    Stack,
    Image,
    rem,
    Flex,
    Divider,
    Text,
    Box,
} from "@mantine/core";
import { IconUpload } from "@tabler/icons-react";
import { v4 as uuidv4 } from "uuid";
import { useSettingsFormContext } from "./SettingsForm";

interface LogoSelectFormProps {
    customerLogoUrl?: string;
}

interface LogoProps {
    src?: string;
    alt?: string;
}

const LogoSelectForm = (props: LogoSelectFormProps) => {
    const { customerLogoUrl } = props;

    const form = useSettingsFormContext();

    const imageUrl = form.values.logo && URL.createObjectURL(form.values.logo);

    const logoSizes = [
        { base: "34px", sm: "42px" },
        { base: "44px", sm: "52px" },
        { base: "64px", sm: "82px" },
    ];

    const renderLogos = ({ src, alt }: LogoProps) => (
        <>
            {logoSizes.map((size) => (
                <Flex
                    bg="#424c5f"
                    w={400}
                    justify="center"
                    align="center"
                    py="5px"
                    key={uuidv4()}
                >
                    <Box
                        component="img"
                        h={size}
                        src={src}
                        alt={alt || "Customer logo"}
                    />
                </Flex>
            ))}
        </>
    );

    return (
        <Flex gap="xl">
            <Stack justify="flex-start" mih={180}>
                <Text size="sm">Current Logo</Text>

                {customerLogoUrl ? (
                    renderLogos({
                        src: customerLogoUrl,
                    })
                ) : (
                    <Image
                        height={120}
                        width={250}
                        src=""
                        alt="Placeholder"
                        withPlaceholder
                        placeholder={
                            <Text align="center">Logo not provided</Text>
                        }
                    />
                )}
            </Stack>

            <Divider orientation="vertical" mx="xl" />

            <Stack justify="flex-start" mih={180} w="100%">
                <FileInput
                    placeholder="Upload logo"
                    description="Accepted files formats: .png, .jpeg, .webp. Max 200KB file size"
                    label="Logo Rebrand"
                    withAsterisk
                    accept="image/png,image/jpeg,image/webp"
                    icon={<IconUpload size={rem(14)} />}
                    radius="md"
                    {...form.getInputProps("logo")}
                    fileInputProps={{ id: "logo_upload" }}
                />
                {imageUrl && (
                    <>
                        <Text>Logo preview</Text>
                        {renderLogos({
                            src: imageUrl,
                            alt: "Customer logo preview",
                        })}
                    </>
                )}
            </Stack>
        </Flex>
    );
};

export default LogoSelectForm;
