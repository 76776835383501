import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { IndexComponent } from "./IndexComponent";

const router = createBrowserRouter([
    {
        path: "/",
        element: <IndexComponent />,
    },
]);

const ApiRootContainer = () => {
    return <RouterProvider router={router} />;
};

export default ApiRootContainer;
