import { Stack, TextInput } from "@mantine/core";
import { IconHash } from "@tabler/icons-react";
import { useSettingsFormContext } from "./SettingsForm";

const PrismicTemplatesForm = () => {
    const form = useSettingsFormContext();

    return (
        <Stack>
            <TextInput
                label="Terms Summary"
                placeholder="Type id"
                radius="md"
                icon={<IconHash size="0.8rem" />}
                withAsterisk
                {...form.getInputProps("termsSummaryId")}
                data-qa="customerSettings_termsSummaryField"
            />

            <TextInput
                label="Full Terms"
                placeholder="Type id"
                radius="md"
                icon={<IconHash size="0.8rem" />}
                withAsterisk
                {...form.getInputProps("fullTermsId")}
                data-qa="customerSettings_fullTermsField"
            />

            <TextInput
                label="Privacy Policy"
                placeholder="Type id"
                radius="md"
                icon={<IconHash size="0.8rem" />}
                withAsterisk
                {...form.getInputProps("privacyPolicyId")}
                data-qa="customerSettings_privacyPolicyField"
            />

            <TextInput
                label="FAQs"
                placeholder="Type id"
                radius="md"
                icon={<IconHash size="0.8rem" />}
                withAsterisk
                {...form.getInputProps("faqId")}
                data-qa="customerSettings_faqField"
            />
        </Stack>
    );
};

export default PrismicTemplatesForm;
