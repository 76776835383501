import { ActionIcon, Box, Tooltip } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { useBoundStore } from "../../store";
import MantineTable from "../../components/MantineTable";

interface AdministratorsTableProps {
    records: any[];
    removeRecord: (recordId: string) => void;
    dataLoading?: boolean;
}

const AdministratorsTable = (props: AdministratorsTableProps) => {
    const { records, removeRecord, dataLoading } = props;

    const { user } = useBoundStore();

    const columns = [
        { accessorKey: "name", header: "Name", sortingFn: "text" },
        { accessorKey: "email", header: "Email", sortingFn: "text" },
        { accessorKey: "role", header: "Role", sortingFn: "text" },
    ];

    const renderActions = (row: any) => (
        <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
            <Tooltip label="Delete administrator" withArrow>
                <ActionIcon
                    color="red"
                    onClick={() => {
                        removeRecord(row.id);
                    }}
                    disabled={row.id === user?.uid}
                >
                    <IconTrash size={20} />
                </ActionIcon>
            </Tooltip>
        </Box>
    );

    return (
        <MantineTable
            records={records}
            columns={columns}
            renderActions={renderActions}
            dataLoading={dataLoading}
            rowStyle={(row) =>
                row.id === user?.uid
                    ? { color: "teal", background: "#F8F8F8" }
                    : {}
            }
        />
    );
};

export default AdministratorsTable;
