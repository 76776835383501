import { ReactNode } from "react";
import {
    Title,
    Divider,
    Button,
    Paper,
    Space,
    Box,
    Select,
} from "@mantine/core";
import { IconDeviceFloppy } from "@tabler/icons-react";
import CustomerDetailsForm from "./CustomerDetailsForm";
import { Customer, WebUiConfig } from "../../store/AppSlice";
import { createFormContext, isNotEmpty } from "@mantine/form";
import {
    validateEmail,
    validatePhone,
    validateLogo,
    validateEndPageContent,
} from "../../validation/validation";
import SidebarForm from "./SidebarForm";
import LogoSelectForm from "./LogoSelectForm";
import PrismicTemplatesForm from "./PrismicTemplatesForm";
import EndPageContentForm from "./EndPageContentForm";

export type FormValues = {
    email: string;
    name: string;
    phone: string;
    country: string;
    active: boolean;
    logo?: File;
    guidanceTitle?: string;
    guidanceContent: string;
    faqTitle: string;
    faqContent: string;
    chatFlow: string;
    linkActiveDuration: string;
    termsSummaryId: string;
    fullTermsId: string;
    privacyPolicyId: string;
    faqId: string;
    isEndPageRequired: boolean;
    endPageHeader: string;
    endPageContent: string;
};

const defaultPrismicTemplates = {
    termsSummaryTemplate: "user-terms-page-default",
    fullTermsTemplate: "terms-page-default",
    privacyPolicyTemplate: "privacy-page-default",
    faqTemplate: "faq-page-default",
};

interface SectionProps {
    title: string;
    children: ReactNode;
}

interface SettingsFormProps {
    customer: Customer | undefined;
    submitting: boolean;
    onSubmit: (values: FormValues) => void;
    webUiConfig: WebUiConfig | undefined;
    chatFlowsData: any[];
}

export const [SettingsFormProvider, useSettingsFormContext, useSettingsForm] =
    createFormContext<FormValues>();

const Section = (props: SectionProps) => {
    const { title, children } = props;

    return (
        <>
            <Title order={2} size="h2" mb="sm">
                {title}
            </Title>
            <Paper radius="md" p="lg" withBorder pos="relative" shadow="md">
                {children}
            </Paper>
            <Space h="lg" />
        </>
    );
};

const SettingsForm = (props: SettingsFormProps) => {
    const { customer, submitting, onSubmit, webUiConfig, chatFlowsData } =
        props;

    const { email, name, phone, country, active, chat_flow, sla_config } =
        customer || {};
    const {
        guidance_title,
        guidance_content,
        faq_title,
        faq_content,
        logo_url,
        terms_summary_id,
        full_terms_id,
        privacy_policy_id,
        faq_id,
        is_end_page_required,
        end_page_header,
        end_page_content,
    } = webUiConfig || {};

    const requiredError = "This field is required";

    const form = useSettingsForm({
        initialValues: {
            email: email || "",
            name: name || "",
            phone: phone || "",
            country: country || "",
            active: active || false,
            guidanceTitle: guidance_title || "",
            guidanceContent: guidance_content || "",
            faqTitle: faq_title || "Need help?",
            faqContent:
                faq_content ||
                "Select the FAQ button below to see Frequently Asked Questions.",
            chatFlow: chat_flow || "",
            linkActiveDuration: `${sla_config?.link_active_duration}` || "1",
            termsSummaryId:
                terms_summary_id ||
                defaultPrismicTemplates.termsSummaryTemplate,
            fullTermsId:
                full_terms_id || defaultPrismicTemplates.fullTermsTemplate,
            privacyPolicyId:
                privacy_policy_id ||
                defaultPrismicTemplates.privacyPolicyTemplate,
            faqId: faq_id || defaultPrismicTemplates.faqTemplate,
            isEndPageRequired: is_end_page_required || false,
            endPageHeader: end_page_header || "",
            endPageContent: end_page_content || "",
        },
        validateInputOnChange: true,
        validateInputOnBlur: true,
        validate: {
            email: (value) => validateEmail(value),
            name: isNotEmpty(requiredError),
            phone: (value) => validatePhone(value),
            logo: (value) => validateLogo(value, logo_url),
            guidanceContent: isNotEmpty(requiredError),
            faqTitle: isNotEmpty(requiredError),
            faqContent: isNotEmpty(requiredError),
            chatFlow: isNotEmpty(requiredError),
            linkActiveDuration: isNotEmpty(requiredError),
            termsSummaryId: isNotEmpty(requiredError),
            fullTermsId: isNotEmpty(requiredError),
            privacyPolicyId: isNotEmpty(requiredError),
            faqId: isNotEmpty(requiredError),
            endPageContent: (value, values) =>
                validateEndPageContent(value, values),
        },
    });

    const formOnSubmit = form.onSubmit((values) => onSubmit(values));

    const chatFlowOptions = chatFlowsData.map((flow) => {
        return { value: flow.id, label: flow.name };
    });

    return (
        <Box>
            <SettingsFormProvider form={form}>
                <form noValidate onSubmit={formOnSubmit}>
                    <Section title="Customer Details">
                        <CustomerDetailsForm />
                    </Section>
                    <Section title="Chat Flow">
                        <Select
                            label="Chat Flow"
                            placeholder="Select chat flow"
                            data={chatFlowOptions || []}
                            radius="md"
                            {...form.getInputProps("chatFlow")}
                            searchable
                            nothingFound="No options"
                            allowDeselect
                            withAsterisk
                            data-qa="customerSettings_chatFlowField"
                        />
                    </Section>
                    <Section title="Branding">
                        <LogoSelectForm customerLogoUrl={logo_url} />
                    </Section>
                    <Section title="Sidebar">
                        <SidebarForm />
                    </Section>
                    <Section title="Prismic Templates">
                        <PrismicTemplatesForm />
                    </Section>
                    <Section title="End Page Wording">
                        <EndPageContentForm />
                    </Section>

                    <Divider my="md" />

                    <Button
                        type="submit"
                        leftIcon={<IconDeviceFloppy />}
                        size="md"
                        disabled={!form.isValid()}
                        loading={submitting}
                    >
                        Save Changes
                    </Button>
                </form>
            </SettingsFormProvider>
        </Box>
    );
};

export default SettingsForm;
