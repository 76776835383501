import DOMPurify from "dompurify";

const sanitizeText = (text: string) => {
    // Add a hook to make all links open a new window
    DOMPurify.addHook("afterSanitizeAttributes", (node: any) => {
        // set all elements owning target to target=_blank
        if ("target" in node) {
            node.setAttribute("target", "_blank");
            node.setAttribute("rel", "noopener noreferrer");
        }
    });

    const sanitazedContent = DOMPurify.sanitize(text, {
        ALLOWED_TAGS: ["p", "strong", "a", "em", "u", "ol", "ul", "li"],
        ALLOWED_ATTR: ["href"],
        KEEP_CONTENT: true,
    });

    return sanitazedContent;
};

export default sanitizeText;
