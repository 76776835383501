import { Box } from "@mantine/core";
import { Helmet } from "react-helmet-async";

const ManagementInformationContainer = () => {
    return (
        <>
            <Helmet>
                <title>GenPhlo - Dashboard</title>
            </Helmet>
            <Box pos="relative">
                <h1>Management Information</h1>
            </Box>
        </>
    );
};

export default ManagementInformationContainer;
