import { Box, Button, Group, TextInput, createStyles } from "@mantine/core";
import { useState } from "react";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: theme.fontSizes.xl,
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
    controls: {
        [theme.fn.smallerThan("xs")]: {
            flexDirection: "column-reverse",
        },
    },
    control: {
        [theme.fn.smallerThan("xs")]: {
            width: "100%",
            textAlign: "center",
        },
    },
}));

interface Props {
    onVerify: (code: string) => void;
}

const MfaCodeVerification = (props: Props) => {
    const { onVerify } = props;
    const [verificationCode, setVerificationCode] = useState("");
    const [loading, setLoading] = useState(false);
    const { classes } = useStyles();

    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    const handleVerificationButtonClicked = async () => {
        setLoading(true);
        await delay(1000);
        if (onVerify) {
            onVerify(verificationCode);
        }
    };
    return (
        <Box>
            <TextInput
                label="Enter Verification Code"
                className={classes.control}
                value={verificationCode}
                onChange={(event) =>
                    setVerificationCode(event.currentTarget.value)
                }
            />
            <Group position="apart" mt="lg" className={classes.controls}>
                <Button
                    loading={loading}
                    onClick={handleVerificationButtonClicked}
                >
                    Verify
                </Button>
            </Group>
        </Box>
    );
};

export default MfaCodeVerification;
