import { HttpsCallableResult, httpsCallable } from "firebase/functions";
import backend from "../../config/DatabaseConfig";
import { ApiDeveloper } from "../../store/AppSlice";
import { CallableResponse } from "./types";

/**
 * Adds a new developer account to the platform
 * @param {Partial<ApiDeveloper>} payload
 * @returns {Promise<HttpsCallableResult<CallableResponse>>}
 */
export const addDeveloperAccount = (
    payload: Partial<ApiDeveloper>
): Promise<HttpsCallableResult<CallableResponse>> => {
    const addDeveloper = httpsCallable<any, CallableResponse>(
        backend.FUNCTIONS,
        "developers-registerDeveloper"
    );
    return addDeveloper(payload);
};

/**
 * Deletes a developer account from the platform
 * @param {object} developer - The developer to be deleted
 * @returns {Promise<HttpsCallableResult<CallableResponse>>}
 */
export const deleteDeveloperAccount = (developer: {
    developerId: string;
}): Promise<HttpsCallableResult<CallableResponse>> => {
    const deleteDeveloper = httpsCallable<any, CallableResponse>(
        backend.FUNCTIONS,
        "developers-deregisterDeveloper"
    );
    return deleteDeveloper(developer);
};
