import React from "react";
import { isNotEmpty, useForm } from "@mantine/form";
import {
    Paper,
    Stack,
    TextInput,
    Button,
    Flex,
    Select,
    MultiSelect,
} from "@mantine/core";
import { validateEmail } from "../../validation/validation";
import { useSetState } from "@mantine/hooks";
import { Customer } from "../../store/AppSlice";

export type FormValues = {
    customerId: string;
    email: string;
    name: string;
    scope: string | string[];
    customerName: string | Customer;
};

interface CustomerFormProps {
    onSubmit: (values: FormValues) => void;
    customerList: Customer[];
}

const DeveloperForm = (props: CustomerFormProps) => {
    const { onSubmit, customerList } = props;

    const [{ loading }, setState] = useSetState({
        loading: false,
    });

    const scopes = [
        "read:customers",
        "write:users",
        "read:users",
        "read:assessments",
    ];

    const form = useForm({
        initialValues: {
            customerId: "",
            email: "",
            name: "",
            scope: [],
        },
        validateInputOnChange: true,
        validateInputOnBlur: true,
        validate: {
            email: (value) => validateEmail(value),
            name: isNotEmpty("Name is required"),
            scope: isNotEmpty("This field is required"),
            customerId: isNotEmpty("This field is required"),
        },
    });

    const formOnSubmit = form.onSubmit(async (values) => {
        try {
            setState({ loading: true });

            await onSubmit({
                customerId: values.customerId,
                email: values.email,
                name: values.name,
                scope: values.scope.toString(),
                customerName:
                    customerList?.find(
                        (customer) => customer.id === values.customerId
                    )?.name || "",
            });
        } catch (e) {
            console.log(e);
        } finally {
            setState({ loading: false });
        }
    });

    return (
        <Paper radius="md" p="md" withBorder pos="relative">
            <form noValidate onSubmit={formOnSubmit}>
                <Stack>
                    <TextInput
                        label="Account Name"
                        placeholder="Name"
                        required
                        radius="md"
                        {...form.getInputProps("name")}
                    />
                    <TextInput
                        required
                        label="Email"
                        placeholder="hello@eql.ai"
                        {...form.getInputProps("email")}
                        radius="md"
                    />
                    <MultiSelect
                        label="Scope"
                        data={scopes.map((scope) => ({
                            value: scope,
                            label: scope,
                        }))}
                        clearable
                        {...form.getInputProps("scope")}
                        required
                    />
                    <Select
                        label="Pick a customer account"
                        data={customerList.map((customer) => ({
                            value: customer.id,
                            label: customer.name,
                        }))}
                        required
                        {...form.getInputProps("customerId")}
                    />
                </Stack>
                <Flex justify="flex-end" mt="md">
                    <Button
                        type="submit"
                        disabled={!form.isValid()}
                        loading={loading}
                    >
                        Add Account
                    </Button>
                </Flex>
            </form>
        </Paper>
    );
};

export default DeveloperForm;
