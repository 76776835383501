import { upperFirst, useSetState } from "@mantine/hooks";
import { isNotEmpty, useForm } from "@mantine/form";
import { Paper, Stack, TextInput, Text, Group, Button } from "@mantine/core";
import { validateEmail } from "../../validation/validation";

export type FormValues = {
    email: string;
    firstName: string;
    lastName: string;
};

interface AdministratorFormProps {
    onSubmit: (values: FormValues) => void;
}

const AdministratorForm = (props: AdministratorFormProps) => {
    const { onSubmit } = props;

    const [{ loading }, setState] = useSetState({
        loading: false,
    });

    const form = useForm({
        initialValues: {
            email: "",
            firstName: "",
            lastName: "",
        },
        validateInputOnChange: true,
        validateInputOnBlur: true,
        validate: {
            email: (value) => validateEmail(value, true),
            firstName: isNotEmpty("First name can't be empty"),
            lastName: isNotEmpty("Last name can't be empty"),
        },
    });

    const formOnSubmit = form.onSubmit(async (values) => {
        try {
            setState({ loading: true });

            await onSubmit(values);
        } catch (e) {
            console.log(e);
        } finally {
            setState({ loading: false });
        }
    });

    return (
        <Paper radius="md" p="md" withBorder pos="relative">
            <form onSubmit={formOnSubmit}>
                <Stack>
                    <TextInput
                        label="First name"
                        placeholder="first name"
                        required
                        radius="md"
                        {...form.getInputProps("firstName")}
                    />
                    <TextInput
                        label="Last name"
                        placeholder="last name"
                        required
                        {...form.getInputProps("lastName")}
                        radius="md"
                    />
                    <TextInput
                        required
                        label="Email"
                        placeholder="hello@eql.ai"
                        {...form.getInputProps("email")}
                        radius="md"
                    />
                </Stack>
                <Group position="apart" mt="xl">
                    <Text size="xs" color="dimmed">
                        Create an administrator
                    </Text>
                    <Button
                        type="submit"
                        disabled={!form.isValid()}
                        loading={loading}
                    >
                        {upperFirst("submit")}
                    </Button>
                </Group>
            </form>
        </Paper>
    );
};

export default AdministratorForm;
