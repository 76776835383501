import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { Stack, Switch, TextInput, Text, Collapse } from "@mantine/core";
import { useSettingsFormContext } from "./SettingsForm";

interface EndPageContentFormProps {}

const RTEComponent = () => {
    const form = useSettingsFormContext();

    const editor = useEditor({
        extensions: [StarterKit, Underline, Link],
        onUpdate(props) {
            const content = props.editor.getHTML();
            form.setFieldValue("endPageContent", content);
        },
        content: form.values.endPageContent,
    });

    return (
        <RichTextEditor editor={editor}>
            <RichTextEditor.Toolbar sticky stickyOffset={60}>
                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Underline />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.BulletList />
                    <RichTextEditor.OrderedList />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Link />
                    <RichTextEditor.Unlink />
                </RichTextEditor.ControlsGroup>
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
        </RichTextEditor>
    );
};

const EndPageContentForm = (props: EndPageContentFormProps) => {
    const form = useSettingsFormContext();

    return (
        <Stack>
            <Text fz="sm" fw={500} mb="-10px">
                End Page Content
            </Text>
            <Switch
                onLabel={<Text fz="sm">Enabled</Text>}
                offLabel={<Text fz="sm">Disabled</Text>}
                size="lg"
                {...form.getInputProps("isEndPageRequired", {
                    type: "checkbox",
                })}
                data-qa="customerSettings_isEndPageRequiredField"
            />
            <Collapse in={form.values.isEndPageRequired}>
                <Stack>
                    <TextInput
                        label="End Page Heading"
                        placeholder="End Page Heading"
                        radius="md"
                        {...form.getInputProps("endPageHeader")}
                        data-qa="customerSettings_endPageHeaderField"
                    />
                    <TextInput
                        label="End Page Content"
                        withAsterisk
                        inputContainer={() => <RTEComponent />}
                        {...form.getInputProps("endPageContent")}
                        data-qa="customerSettings_endPageContentField"
                    />
                </Stack>
            </Collapse>
        </Stack>
    );
};

export default EndPageContentForm;
