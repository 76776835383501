import {
    createStyles,
    Paper,
    Title,
    Text,
    Button,
    Container,
    rem,
    PasswordInput,
    Stack,
    Flex,
    List,
    ThemeIcon,
} from "@mantine/core";
import { IconCircleCheck, IconCircleX } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { matchesField, useForm } from "@mantine/form";
import {
    validatePassword,
    lengthRegex,
    upperCaseRegex,
    lowerCaseRegex,
    numericRegex,
    specialCharRegex,
} from "../../validation/validation";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(26),
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    controls: {
        [theme.fn.smallerThan("xs")]: {
            flexDirection: "column-reverse",
        },
    },

    control: {
        [theme.fn.smallerThan("xs")]: {
            width: "100%",
            textAlign: "center",
        },
    },

    error: { display: "none" },
    wrapper: { marginBottom: 0 },
}));

interface ResetPasswordProps {
    onSubmit: (email: string) => Promise<void>;
    loading?: boolean;
    isVerified: boolean;
}

const ResetPassword = (props: ResetPasswordProps) => {
    const { onSubmit, loading, isVerified } = props;

    const [visible, { toggle }] = useDisclosure(false);

    const { classes } = useStyles();

    const form = useForm({
        initialValues: {
            password: "",
            confirmPassword: "",
        },

        validate: {
            password: (value) => validatePassword(value, true),
            confirmPassword: matchesField(
                "password",
                "Passwords are not the same"
            ),
        },

        validateInputOnChange: true,
        validateInputOnBlur: true,
    });

    const formOnSubmit = form.onSubmit((values) => onSubmit(values.password));

    const renderIcon = (regex: RegExp) => {
        const isValid = regex.test(form.values.password);
        return (
            <ThemeIcon
                color={isValid ? "teal" : "lightGrey"}
                size={20}
                radius="xl"
            >
                {isValid ? (
                    <IconCircleCheck size="1rem" />
                ) : (
                    <IconCircleX size="1rem" />
                )}
            </ThemeIcon>
        );
    };

    return (
        <Container size={460} my={30}>
            <Title className={classes.title} align="center">
                Reset password?
            </Title>
            {isVerified ? (
                <>
                    <Text c="dimmed" fz="sm" ta="center">
                        Please enter your new password
                    </Text>

                    <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                        <form noValidate onSubmit={formOnSubmit}>
                            <Stack mx="auto">
                                <PasswordInput
                                    label="Password"
                                    placeholder="Password"
                                    visible={visible}
                                    onVisibilityChange={toggle}
                                    required
                                    {...form.getInputProps("password")}
                                    classNames={{
                                        error: classes.error,
                                        wrapper: classes.wrapper,
                                    }}
                                />
                                <List spacing={5} size="xs" center>
                                    <List.Item icon={renderIcon(lengthRegex)}>
                                        Must have 8 or more characters
                                    </List.Item>
                                    <List.Item
                                        icon={renderIcon(upperCaseRegex)}
                                    >
                                        Must contain an upper case character
                                    </List.Item>
                                    <List.Item
                                        icon={renderIcon(lowerCaseRegex)}
                                    >
                                        Must contain a lower case character
                                    </List.Item>
                                    <List.Item icon={renderIcon(numericRegex)}>
                                        Must contain a numeric character
                                    </List.Item>
                                    <List.Item
                                        icon={renderIcon(specialCharRegex)}
                                    >
                                        Must contain a special character
                                    </List.Item>
                                </List>
                                <PasswordInput
                                    label="Confirm password"
                                    placeholder="Confirm password"
                                    visible={visible}
                                    onVisibilityChange={toggle}
                                    required
                                    {...form.getInputProps("confirmPassword")}
                                />
                            </Stack>

                            <Flex justify="flex-end" mt="md">
                                <Button
                                    type="submit"
                                    disabled={!form.isValid()}
                                    className={classes.control}
                                    loading={loading}
                                >
                                    Reset password
                                </Button>
                            </Flex>
                        </form>
                    </Paper>
                </>
            ) : (
                <Text c="dimmed" fz="xl" ta="center" mt="md">
                    Sorry, that password reset code is invalid or may have
                    expired.
                </Text>
            )}
        </Container>
    );
};

export default ResetPassword;
