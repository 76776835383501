import {
    Container,
    Title,
    Text,
    createStyles,
    rem,
    Paper,
    TextInput,
    Button,
    Group,
    Box,
    Flex,
} from "@mantine/core";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import MfaCodeVerification from "./MfaCodeVerification";
import { verifyProvidedPhoneNumberForUser } from "../../services/mfa";
import { useBoundStore } from "../../store";
import { useNavigate } from "react-router-dom";
import FormattedLink from "../../components/FormattedLink";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(26),
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    controls: {
        [theme.fn.smallerThan("xs")]: {
            flexDirection: "column-reverse",
        },
    },

    control: {
        [theme.fn.smallerThan("xs")]: {
            width: "100%",
            textAlign: "center",
        },
    },
}));

const Enrollment = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [showVerification, setShowVerification] = useState(false);
    const [verificationCodeId, setVerificationCodeId] = useState("");
    const { classes } = useStyles();
    const { user, enrollMultifactor } = useBoundStore();

    const navigate = useNavigate();

    const handleEnroll = async () => {
        try {
            setLoading(true);
            if (!user) {
                setLoading(false);
                return;
            }
            const codeId = await verifyProvidedPhoneNumberForUser(
                phoneNumber,
                user
            );
            setLoading(false);
            setVerificationCodeId(codeId);
            setShowVerification(true);
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    };

    const handleVerifyCodeEntry = async (code: string) => {
        if (!user) {
            return;
        }
        await enrollMultifactor(user, verificationCodeId, code);
        navigate("/dashboard");
    };

    return (
        <>
            <Helmet>
                <title>2FA Enrollment</title>
            </Helmet>
            <Container size={460} my={30}>
                <Title className={classes.title} align="center">
                    Multifactor Authentication Enrollment
                </Title>
                <Text ta="center">
                    Enter your phone number to enroll in multifactor.
                </Text>
                <Text c="dimmed" fz="sm" ta="center">
                    We will send a code to your phone number. You will need it
                    for the next step.
                </Text>
                <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                    {showVerification ? (
                        <MfaCodeVerification
                            onVerify={(code) => {
                                handleVerifyCodeEntry(code);
                            }}
                        />
                    ) : (
                        <Box>
                            <TextInput
                                className={classes.control}
                                placeholder="Phone number"
                                label="Phone number"
                                required
                                type="tel"
                                data-qa="2faEnrol-button"
                                onChange={(event) =>
                                    setPhoneNumber(event.currentTarget.value)
                                }
                            />
                            <Group
                                position="apart"
                                mt="lg"
                                className={classes.controls}
                            >
                                <Button
                                    loading={loading}
                                    onClick={handleEnroll}
                                >
                                    Enrol
                                </Button>
                            </Group>
                        </Box>
                    )}
                    <Flex justify="right" mt="md">
                        <FormattedLink size="sm" href="/" data-qa="login">
                            Login
                        </FormattedLink>
                    </Flex>
                </Paper>
            </Container>
        </>
    );
};

export default Enrollment;
