import {
    Code,
    Group,
    Header,
    rem,
    Image,
    Button,
    Avatar,
    Badge,
    Text,
    Burger,
    useMantineTheme,
} from "@mantine/core";
import Logo from "../assets/images/genphlo-logo.png";
import packagedata from "../../package.json";
import { useBoundStore } from "../store";
import { IconDoorExit } from "@tabler/icons-react";
import EnvUtil from "../utils/EnvUtil";

interface CustomHeaderProps {
    opened: boolean;
    setOpened: (opened: boolean) => void;
}

const env = EnvUtil.getEnv(window.location.host);

export const CustomHeader = (props: CustomHeaderProps) => {
    const { opened, setOpened } = props;

    const { user, logout } = useBoundStore();
    const theme = useMantineTheme();

    return (
        <Header height={{ base: 100, md: 60 }} p="md">
            <Group noWrap position="apart" align="flex-start">
                <Group noWrap spacing="xs">
                    <Burger
                        opened={opened}
                        onClick={() => setOpened(!opened)}
                        size="sm"
                        color={theme.colors.gray[6]}
                        mt="5px"
                    />

                    <Group noWrap spacing="xs">
                        <Image src={Logo} width={rem(120)} />
                        <Code
                            sx={{
                                height: "22px",
                                fontWeight: 700,
                            }}
                        >{`v${packagedata.version}`}</Code>
                    </Group>
                </Group>
                <Group position="right">
                    {env !== "prod" && <Badge>{env}</Badge>}

                    <Group spacing={7}>
                        <Avatar
                            src={user?.photoURL}
                            alt="User Avatar"
                            radius="xl"
                            size={20}
                        />
                        <Text
                            weight={500}
                            size="sm"
                            sx={{ lineHeight: 1 }}
                            mr={3}
                        >
                            {user?.displayName}
                        </Text>
                    </Group>

                    <Button
                        variant="outline"
                        size="xs"
                        color="gray"
                        leftIcon={<IconDoorExit />}
                        onClick={logout}
                        data-qa="nav_logoutButton"
                    >
                        Sign Out
                    </Button>
                </Group>
            </Group>
        </Header>
    );
};
