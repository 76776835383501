import { isNotEmpty, useForm } from "@mantine/form";
import { Paper, Stack, TextInput, Button, Flex } from "@mantine/core";
import { validateEmail, validatePhone } from "../../validation/validation";
import PhoneField from "../../components/PhoneField";
import { useSetState } from "@mantine/hooks";

export type FormValues = {
    email: string;
    name: string;
    phone: string;
    country: string;
};

interface CustomerFormProps {
    onSubmit: (values: FormValues) => void;
}

const CustomerForm = (props: CustomerFormProps) => {
    const { onSubmit } = props;

    const [{ loading }, setState] = useSetState({
        loading: false,
    });

    const form = useForm({
        initialValues: {
            email: "",
            name: "",
            phone: "",
            country: "",
        },
        validateInputOnChange: true,
        validateInputOnBlur: true,
        validate: {
            email: (value) => validateEmail(value),
            name: isNotEmpty("Name is required"),
            phone: (value) => validatePhone(value),
        },
    });

    const formOnSubmit = form.onSubmit(async (values) => {
        try {
            setState({ loading: true });

            await onSubmit(values);
        } catch (e) {
            console.log(e);
        } finally {
            setState({ loading: false });
        }
    });

    return (
        <Paper radius="md" p="md" withBorder pos="relative">
            <form noValidate onSubmit={formOnSubmit}>
                <Stack>
                    <TextInput
                        label="Name"
                        placeholder="Customer name"
                        required
                        radius="md"
                        data-qa="customer_nameField"
                        {...form.getInputProps("name")}
                    />
                    <TextInput
                        required
                        label="Email"
                        placeholder="hello@eql.ai"
                        data-qa="customer_emailField"
                        {...form.getInputProps("email")}
                        radius="md"
                    />
                    <PhoneField
                        required
                        label="Phone"
                        {...form.getInputProps("phone")}
                        onChange={(phone: string, country: string) => {
                            form.setFieldError("phone", null);
                            form.setValues({ phone, country });
                            form.isTouched("phone") &&
                                form.setFieldError(
                                    "phone",
                                    validatePhone(phone)
                                );
                        }}
                        radius="md"
                    />
                </Stack>
                <Flex justify="flex-end" mt="md">
                    <Button
                        type="submit"
                        disabled={!form.isValid()}
                        loading={loading}
                        data-qa="customer_saveButton"
                    >
                        Submit
                    </Button>
                </Flex>
            </form>
        </Paper>
    );
};

export default CustomerForm;
