import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useBoundStore } from "../store";

const RootLayout = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useBoundStore();
    React.useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
        } else {
            navigate("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <div>
            <Outlet />
        </div>
    );
};

export default RootLayout;
