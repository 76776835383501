import { ReactNode, useMemo } from "react";
import {
    type MRT_ColumnDef,
    MantineReactTable,
    useMantineReactTable,
} from "mantine-react-table";
import { useMantineTheme } from "@mantine/core";

interface Sort {
    id: string;
    desc: boolean;
}

interface TableProps {
    records: any[];
    columns: any[];
    renderActions?: (row?: any) => ReactNode;
    dataLoading?: boolean;
    rowExpansion?: any;
    sorting?: Sort[];
    enableSorting?: boolean;
    rowStyle?: (row?: any) => any;
    renderDetails?: (row?: any) => ReactNode;
}

const MantineTable = (props: TableProps) => {
    const {
        records,
        renderActions,
        columns,
        dataLoading,
        sorting,
        enableSorting,
        rowStyle,
        renderDetails,
    } = props;

    const columnsData = useMemo<MRT_ColumnDef<any>[]>(
        () => [...columns],
        [columns]
    );
    const data = useMemo(() => [...records], [records]);

    const { colorScheme } = useMantineTheme();

    const table = useMantineReactTable({
        columns: columnsData,
        data: !data || dataLoading ? [] : data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        enableGlobalFilter: false,
        enableColumnFilters: false,
        mantineTableContainerProps: {
            sx: { minHeight: "600px", maxHeight: "600px" },
        },
        enableSorting: enableSorting || true,
        initialState: {
            density: "xs",
            sorting: sorting || [],
            columnOrder: renderDetails
                ? ["mrt-row-expand", "mrt-row-actions"]
                : ["mrt-row-actions"],
        },
        state: {
            isLoading: dataLoading,
            showLoadingOverlay: dataLoading, //fetching next page pagination
            showSkeletons: dataLoading, //loading for the first time with no data
        },
        enableStickyHeader: true,
        enableRowActions: !!renderActions,
        renderRowActions: ({ row }) =>
            renderActions && renderActions(row.original),
        mantineTableProps: {
            withColumnBorders: true,
            withBorder: colorScheme === "light",
        },
        mantineTableBodyRowProps: ({ row }) => ({
            sx: rowStyle && rowStyle(row.original),
        }),
        renderDetailPanel: ({ row }) =>
            renderDetails && renderDetails(row.original),
        positionExpandColumn: "first",
        positionActionsColumn: "first",
    });

    return <MantineReactTable table={table} />;
};

export default MantineTable;
