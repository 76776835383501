import React, { useState } from "react";
import { AppShell } from "@mantine/core";
import { Outlet } from "react-router-dom";
import CustomSideNav from "../components/CustomSideNav";
import { CustomHeader } from "../components/CustomHeader";

const DashboardLayout = () => {
    const [opened, setOpened] = useState(false);

    return (
        <AppShell
            padding="md"
            navbar={<CustomSideNav opened={opened} />}
            header={<CustomHeader opened={opened} setOpened={setOpened} />}
        >
            <Outlet />
        </AppShell>
    );
};

export default DashboardLayout;
