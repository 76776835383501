import {
    User,
    signOut,
    signInWithEmailAndPassword,
    MultiFactorResolver,
} from "firebase/auth";
import { StateCreator } from "zustand";
import backend from "../config/DatabaseConfig";
import {
    isMfaEnabled,
    signInWithMultifactorResolver,
    verify2FAcodeForUser,
} from "../services/mfa";

export interface AuthSlice {
    isAuthenticated: boolean;
    user: User | null;
    multifactorEnabled: boolean;

    /**
     * Sign in with email and password
     * @param {string} email
     * @param {string} password
     */
    login(email: string, password: string): Promise<void>;
    /**
     * Sign out
     */
    logout(): Promise<void>;
    /**
     * Login with multifactor resolver
     * @param resolver
     * @param verificationCodeId
     * @param verificationCode
     */
    loginWithMultifactorResolver(
        resolver: MultiFactorResolver,
        verificationCodeId: string,
        verificationCode: string
    ): Promise<void>;

    enrollMultifactor(
        user: User,
        verificationCodeId: string,
        verificationCode: string
    ): Promise<void>;
}

export const createAuthSlice: StateCreator<AuthSlice> = (set, get) => ({
    login: async (email: string, password: string) => {
        const userCredential = await signInWithEmailAndPassword(
            backend.AUTH,
            email,
            password
        );
        // Check if the user has multifactor enabled
        const multifactorEnabled = isMfaEnabled(userCredential.user);
        if (!multifactorEnabled) {
            set({ multifactorEnabled: false, user: userCredential.user });
            return;
        }
        set({ isAuthenticated: true, user: userCredential.user });
    },
    logout: async () => {
        try {
            await signOut(backend.AUTH);
            set({ isAuthenticated: false, user: null });
        } catch (error) {
            console.error(error);
        }
    },
    loginWithMultifactorResolver: async (
        resolver: MultiFactorResolver,
        verificationCodeId: string,
        verificationCode: string
    ) => {
        try {
            const creds = await signInWithMultifactorResolver(
                resolver,
                verificationCodeId,
                verificationCode
            );
            set({ isAuthenticated: true, user: creds.user });
        } catch (e) {
            console.error(e);
        }
    },
    enrollMultifactor: async (
        user: User,
        verificationCodeId: string,
        verificationCode: string
    ) => {
        try {
            // Enroll the user in multifactor
            await verify2FAcodeForUser(
                user,
                verificationCodeId,
                verificationCode
            );
            set({ multifactorEnabled: true, user, isAuthenticated: true });
        } catch (e) {
            console.error(e);
        }
    },
    isAuthenticated: false,
    user: null,
    multifactorEnabled: false,
});
