import { validatePhone as IPvalidation } from "react-international-phone";
import { FormValues } from "../containers/settings/SettingsForm";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const internalEmailRegex = /^[a-zA-Z0-9._%+-]+@eql\.ai+$/;
export const lengthRegex = /.{8,}/;
export const upperCaseRegex = /[A-Z]/;
export const lowerCaseRegex = /[a-z]/;
export const numericRegex = /[0-9]/;
export const specialCharRegex = /[!@#$%^&*]/;

export const validateEmail = (value: string, internal?: boolean) => {
    const trimValue = value.trim();

    if (!trimValue) {
        return "This field is required";
    }
    if (!emailRegex.test(trimValue)) {
        return "Please enter a valid email address";
    }
    if (internal && !internalEmailRegex.test(trimValue)) {
        return "Please enter a valid email address";
    }
    return null;
};

export const validatePassword = (value: string, noMessage?: boolean) => {
    if (!lengthRegex.test(value)) {
        return noMessage ? " " : "Password must have 8 or more characters";
    }
    if (!upperCaseRegex.test(value)) {
        return noMessage
            ? " "
            : "Password must contain an upper case character";
    }
    if (!lowerCaseRegex.test(value)) {
        return noMessage ? " " : "Password must contain a lower case character";
    }
    if (!numericRegex.test(value)) {
        return noMessage ? " " : "Password must contain a numeric character";
    }
    if (!specialCharRegex.test(value)) {
        return noMessage ? " " : "Password must contain a special character";
    }
    return null;
};

export const validatePhone = (value: string) => {
    const phoneValue = value.substring(value.indexOf(" ") + 1);
    const isPhoneValid = IPvalidation(value).isValid;

    if (!phoneValue) {
        return "This field is required";
    }

    if (!!phoneValue && !isPhoneValid) {
        return "Invalid phone number";
    }
    return null;
};

export const validateLogo = (
    value: File | undefined,
    currentValue: string | undefined
) => {
    if (!value && !currentValue) {
        return "This field is required";
    }
    if (value && value.size > 204800) {
        return "This file is too big. Please upload a file smaller than 200KB";
    }
    return null;
};

export const validateEndPageContent = (value: string, values: FormValues) => {
    const trimValue = value.trim();

    if (values.isEndPageRequired && (!trimValue || trimValue === "<p></p>")) {
        return "This field is required";
    }

    return null;
};
