import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { AuthSlice, createAuthSlice } from "./AuthSlice";
import { AppSlice, createAppSlice } from "./AppSlice";

export const useBoundStore = create<AuthSlice & AppSlice>()(
  devtools(
    persist(
      (...a) => ({
        ...createAuthSlice(
          // @ts-ignore
          ...a
        ),
        ...createAppSlice(
          // @ts-ignore
          ...a
        ),
      }),
      { name: "boundStore" }
    )
  )
);
