import React from "react";
import {
    createStyles,
    Paper,
    Title,
    Text,
    TextInput,
    Button,
    Container,
    Group,
    Center,
    rem,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import FormattedLink from "../../components/FormattedLink";
import { useForm } from "@mantine/form";
import { validateEmail } from "../../validation/validation";
import { Helmet } from "react-helmet-async";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(26),
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    controls: {
        [theme.fn.smallerThan("xs")]: {
            flexDirection: "column-reverse",
        },
    },

    control: {
        [theme.fn.smallerThan("xs")]: {
            width: "100%",
            textAlign: "center",
        },
    },
}));

interface ForgotPasswordProps {
    onSubmit: (email: string) => Promise<void>;
    loading: boolean;
}

const ForgotPassword = (props: ForgotPasswordProps) => {
    const { onSubmit, loading } = props;

    const { classes } = useStyles();

    const form = useForm({
        initialValues: {
            email: "",
        },

        validate: {
            email: (value) => validateEmail(value, true),
        },
        validateInputOnChange: true,
        validateInputOnBlur: true,
    });

    const formOnSubmit = form.onSubmit(async (values) => {
        await onSubmit(values.email);
        form.reset();
    });

    return (
        <>
            <Helmet>
                <title>GenPhlo - Forgot Password</title>
            </Helmet>
            <Container size={460} my={30}>
                <Title className={classes.title} align="center">
                    Forgot your password?
                </Title>
                <Text c="dimmed" fz="sm" ta="center">
                    Enter your email to get a reset link
                </Text>

                <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                    <form noValidate onSubmit={formOnSubmit}>
                        <TextInput
                            label="Your email"
                            placeholder="me@mantine.dev"
                            required
                            data-qa="forgotPassword_emailField"
                            {...form.getInputProps("email")}
                        />
                        <Group
                            position="apart"
                            mt="lg"
                            className={classes.controls}
                        >
                            <Center inline>
                                <IconArrowLeft
                                    size={rem(12)}
                                    stroke={1.5}
                                    color="grey"
                                />
                                <FormattedLink
                                    ml={5}
                                    href="/"
                                    color="dimmed"
                                    size="sm"
                                    dataqa="forgotPassword_backLink"
                                >
                                    Back to the login page
                                </FormattedLink>
                            </Center>

                            <Button
                                type="submit"
                                disabled={!form.isValid()}
                                className={classes.control}
                                loading={loading}
                                data-qa="forgotPassword_submitButton"
                            >
                                Reset password
                            </Button>
                        </Group>
                    </form>
                </Paper>
            </Container>
        </>
    );
};

export default ForgotPassword;
