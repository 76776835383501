import { useEffect } from "react";
import { Button, Container, Group, Stack, Text, Title } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useSetState } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import CustomerForm from "./CustomerForm";
import CustomerTable from "./CustomerTable";
import { useBoundStore } from "../../store";

import type { FormValues } from "./CustomerForm";

interface CustomerState {
    tableLoading: boolean;
}

const CustomerListContainer = () => {
    const [{ tableLoading }, setState] = useSetState<CustomerState>({
        tableLoading: false,
    });

    const {
        customers,
        getCustomers,
        addCustomer,
        isCustomerCreated,
        deleteCustomer,
        isCustomerDeleted,
        getChatFlows,
        chatFlows,
    } = useBoundStore();

    const getListOfCustomers = async () => {
        try {
            setState({ tableLoading: true });

            await getCustomers();
            await getChatFlows();
        } catch (e) {
            console.log(e);
        } finally {
            setState({ tableLoading: false });
        }
    };

    const handleAddCustomer = async ({
        name,
        email,
        phone,
        country,
    }: FormValues) => {
        try {
            await addCustomer({
                name,
                email,
                phone,
                country,
            });

            await modals.closeAll();

            if (!isCustomerCreated) return;

            notifications.show({
                title: "Success!",
                message: "Customer successfully created.",
                icon: <IconCheck />,
            });

            getListOfCustomers();
        } catch (e) {
            console.log(e);
            const error = e as Error;
            notifications.show({
                title: "Error",
                message: error.message,
                color: "red",
                icon: <IconX />,
            });
        }
    };

    const handleOpenCustomerFormModal = () => {
        modals.open({
            title: (
                <Text fz="xl" fw={500}>
                    Create customer
                </Text>
            ),
            centered: true,
            children: (
                <CustomerForm
                    onSubmit={async (values) => {
                        await handleAddCustomer(values);
                    }}
                />
            ),
        });
    };

    const removeCustomer = async (recordId: string): Promise<void> => {
        try {
            setState({ tableLoading: true });

            await deleteCustomer(recordId);

            await modals.close("deleteCustomer");

            if (!isCustomerDeleted) return;

            notifications.show({
                title: "Success!",
                message: "Customer successfully deleted.",
                icon: <IconCheck />,
            });

            getListOfCustomers();
        } catch (e) {
            console.log(e);
            const error = e as Error;
            notifications.show({
                title: "Error",
                message: error.message,
                color: "red",
                icon: <IconX />,
            });
        } finally {
            setState({ tableLoading: false });
        }
    };

    const handleRemoveRecord = (recordId: string) => {
        modals.openConfirmModal({
            id: "deleteCustomer",
            title: (
                <Text fz="xl" fw={500}>
                    Delete Customer
                </Text>
            ),
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete this customer?
                </Text>
            ),
            onCancel: () => modals.close("deleteCustomer"),
            onConfirm: () => removeCustomer(recordId),
        });
    };

    useEffect(() => {
        (async () => {
            await getListOfCustomers();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCustomerCreated, isCustomerDeleted]);

    return (
        <Container size="xl" p="sm">
            <Stack>
                <Group position="apart">
                    <Title order={1} size="h2">
                        Customers List
                    </Title>
                    <Button onClick={handleOpenCustomerFormModal}>
                        Add Customer
                    </Button>
                </Group>

                <CustomerTable
                    records={customers}
                    removeRecord={handleRemoveRecord}
                    dataLoading={tableLoading}
                    chatFlows={chatFlows}
                />
            </Stack>
        </Container>
    );
};

export default CustomerListContainer;
