import {
    ActionIcon,
    Box,
    Button,
    CopyButton,
    Group,
    Stack,
    Text,
    Tooltip,
} from "@mantine/core";
import { IconCheck, IconCopy, IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import { Referral } from "../../store/AppSlice";
import MantineTable from "../../components/MantineTable";

interface ReferralsTableProps {
    records: any[];
    removeRecord: (recordId: string) => void;
    dataLoading: boolean;
}

const ReferralsTable = (props: ReferralsTableProps) => {
    const { records, dataLoading, removeRecord } = props;

    const columns = [
        { accessorKey: "id", header: "ID", sortingFn: "text" },
        {
            id: "last_name",
            header: "Full Name",
            accessorFn: (row: Referral) => `${row.first_name} ${row.last_name}`,
            sortingFn: "text",
        },
        {
            accessorKey: "status",
            header: "Status",
            size: 100,
            sortingFn: "text",
        },
        {
            id: "referral_link",
            header: "Link",
            size: 120,
            accessorFn: (row: Referral) => {
                if (!row?.referral_url) return null;
                return (
                    <CopyButton value={row?.referral_url} timeout={2000}>
                        {({ copied, copy }) => (
                            <Tooltip
                                label={copied ? "Copied" : "Copy"}
                                withArrow
                                position="right"
                            >
                                <Button
                                    color={copied ? "teal" : "default"}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        copy();
                                    }}
                                    rightIcon={
                                        copied ? (
                                            <IconCheck size="1rem" />
                                        ) : (
                                            <IconCopy size="1rem" />
                                        )
                                    }
                                    variant="subtle"
                                >
                                    Copy url
                                </Button>
                            </Tooltip>
                        )}
                    </CopyButton>
                );
            },
        },
        {
            id: "referral_type",
            header: "Type",
            size: 80,
            accessorFn: (row: Referral) => row.referral_type || "api",
            sortingFn: "text",
        },
        {
            id: "created_at",
            header: "Created on",
            size: 120,
            accessorFn: (row: Referral) => {
                if (!row.created_at) return null;
                const date = dayjs.unix(row.created_at.seconds);
                return date;
            },
            Cell: ({ cell }: any) =>
                dayjs(cell.getValue()).format("DD/MM/YYYY"),
            sortingFn: "datetime",
        },
    ];

    const renderActions = (row: Referral) => (
        <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
            <Tooltip label="Delete referral" withArrow>
                <ActionIcon
                    color="red"
                    onClick={() => {
                        removeRecord(row.id);
                    }}
                >
                    <IconTrash size={20} />
                </ActionIcon>
            </Tooltip>
        </Box>
    );

    const renderDetailsPanel = (row: Referral) => (
        <Stack p="xs" spacing={6}>
            <Group spacing={6}>
                <Text fw={500}>Email:</Text>
                <Text>{row.email}</Text>
            </Group>
            <Group spacing={6}>
                <Text fw={500}>Phone:</Text>
                <Text>{row.phone}</Text>
            </Group>
            <Group spacing={6}>
                <Text fw={500}>Date of Birth:</Text>
                <Text>{dayjs(row.dob).format("DD/MM/YYYY")}</Text>
            </Group>
        </Stack>
    );

    return (
        <MantineTable
            records={records}
            columns={columns}
            renderActions={renderActions}
            dataLoading={dataLoading}
            sorting={[
                {
                    id: "created_at", //sort by age by default on page load
                    desc: true,
                },
            ]}
            renderDetails={renderDetailsPanel}
        />
    );
};

export default ReferralsTable;
