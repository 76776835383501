import React, { useEffect } from "react";
import { Container, Stack, Button, Group, Title, Text } from "@mantine/core";
import AdministratorsTable from "./AdministratorsTable";
import AdministratorForm, { FormValues } from "./AdministratorForm";
import { notifications } from "@mantine/notifications";
import { useBoundStore } from "../../store";
import { deleteAdminUser } from "../../services/callables/admins";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useSetState } from "@mantine/hooks";
import { addAdminUser } from "../../services/callables/admins";

interface StateProps {
    tableLoading: boolean;
}

const AdminUserListContainer = () => {
    const [{ tableLoading }, setState] = useSetState<StateProps>({
        tableLoading: false,
    });

    const { getAdministrators, administrators } = useBoundStore();

    const getListOfAdministrators = async () => {
        try {
            setState({ tableLoading: true });

            await getAdministrators();
        } catch (e) {
            console.log(e);
        } finally {
            setState({ tableLoading: false });
        }
    };

    const handleAddAdmin = async ({
        email,
        firstName,
        lastName,
    }: FormValues) => {
        try {
            await addAdminUser({
                email,
                lastName,
                firstName,
                role: "admin",
            });

            await modals.closeAll();

            notifications.show({
                title: "Success!",
                message: "Admin user successfully created.",
                icon: <IconCheck />,
            });

            await getListOfAdministrators();
        } catch (e) {
            console.log(e);
            const error = e as Error;
            notifications.show({
                title: "Error",
                message: error.message,
                color: "red",
                icon: <IconX />,
            });
        }
    };

    const handleOpenAdminFormModal = () => {
        modals.open({
            title: (
                <Text fz="xl" fw={500}>
                    Create new admin user
                </Text>
            ),
            centered: true,
            children: (
                <AdministratorForm
                    onSubmit={async (values) => {
                        await handleAddAdmin(values);
                    }}
                />
            ),
        });
    };

    const removeAdmin = async (recordId: string): Promise<void> => {
        try {
            setState({ tableLoading: true });

            await deleteAdminUser(recordId);

            await modals.close("deleteAdmin");

            notifications.show({
                title: "Success!",
                message: "User successfully deleted.",
                icon: <IconCheck />,
            });

            await getListOfAdministrators();
        } catch (e) {
            console.log(e);
            const error = e as Error;
            notifications.show({
                title: "Error",
                message: error.message,
                color: "red",
                icon: <IconX />,
            });
        } finally {
            setState({ tableLoading: false });
        }
    };

    const handleRemoveRecord = (recordId: string) => {
        modals.openConfirmModal({
            id: "deleteAdmin",
            title: (
                <Text fz="xl" fw={500}>
                    Delete User
                </Text>
            ),
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete this admin user?
                </Text>
            ),
            onCancel: () => modals.close("deleteAdmin"),
            onConfirm: () => removeAdmin(recordId),
        });
    };

    useEffect(() => {
        (async () => {
            await getListOfAdministrators();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container size="xl" p="sm">
            <Stack>
                <Group position="apart">
                    <Title order={1} size="h2">
                        Administrators
                    </Title>
                    <Button onClick={handleOpenAdminFormModal}>
                        Add New Administrator
                    </Button>
                </Group>
                <AdministratorsTable
                    records={administrators}
                    removeRecord={handleRemoveRecord}
                    dataLoading={tableLoading}
                />
            </Stack>
        </Container>
    );
};

export default AdminUserListContainer;
