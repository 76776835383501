import { Button, Container, Group, Stack, Text, Title } from "@mantine/core";
import DeveloperTable from "./DeveloperTable";
import React from "react";
import { useBoundStore } from "../../store";
import { useSetState } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import DeveloperForm, { FormValues } from "./DeveloperForm";

interface StateProps {
    tableLoading: boolean;
}

const DeveloperListContainer = () => {
    const [{ tableLoading }, setState] = useSetState<StateProps>({
        tableLoading: false,
    });

    const {
        customers,
        getCustomers,
        developers,
        getDevelopers,
        deleteDeveloper,
        isDeveloperCreated,
        addDeveloper,
    } = useBoundStore();

    const getListOfDevelopers = async () => {
        try {
            setState({ tableLoading: true });

            await getDevelopers();
        } catch (e) {
            console.log(e);
        } finally {
            setState({ tableLoading: false });
        }
    };

    const removeDeveloper = async (recordId: string): Promise<void> => {
        try {
            setState({ tableLoading: true });

            await deleteDeveloper(recordId);

            await modals.close("deleteDeveloper");

            notifications.show({
                title: "Success!",
                message: "User successfully deleted.",
                icon: <IconCheck />,
            });

            getListOfDevelopers();
        } catch (e) {
            console.log(e);
            const error = e as Error;
            notifications.show({
                title: "Error",
                message: error.message,
                color: "red",
                icon: <IconX />,
            });
        } finally {
            setState({ tableLoading: false });
        }
    };

    const handleRemoveRecord = (recordId: string) => {
        modals.openConfirmModal({
            id: "deleteDeveloper",
            title: (
                <Text fz="xl" fw={500}>
                    Delete Developer
                </Text>
            ),
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete this developer?
                </Text>
            ),
            onCancel: () => modals.close("deleteDeveloper"),
            onConfirm: () => removeDeveloper(recordId),
        });
    };

    const handleAddDeveloper = async ({
        customerId,
        name,
        email,
        scope,
        customerName,
    }: FormValues) => {
        try {
            const devAccountPayload = {
                customerId,
                name,
                email,
                scope,
                customerName,
            };
            await addDeveloper(devAccountPayload);

            await modals.closeAll();

            if (!isDeveloperCreated) return;

            notifications.show({
                title: "Success!",
                message: "Developer successfully created.",
                icon: <IconCheck />,
            });

            getListOfDevelopers();
        } catch (e) {
            console.log(e);
            const error = e as Error;
            notifications.show({
                title: "Error",
                message: error.message,
                color: "red",
                icon: <IconX />,
            });
        }
    };

    const handleOpenDeveloperFormModal = () => {
        modals.open({
            title: (
                <Text fz="xl" fw={500}>
                    Create developer
                </Text>
            ),
            centered: true,
            children: (
                <DeveloperForm
                    onSubmit={async (values) => {
                        await handleAddDeveloper(values);
                    }}
                    customerList={customers}
                />
            ),
        });
    };

    React.useEffect(() => {
        (async () => {
            await getListOfDevelopers();
            await getCustomers();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeveloperCreated]);

    return (
        <Container size="xl" p="sm">
            <Stack>
                <Group position="apart">
                    <Title order={1} size="h2">
                        Developers List
                    </Title>
                    <Button onClick={handleOpenDeveloperFormModal}>
                        Add A Developer Account
                    </Button>
                </Group>
                <DeveloperTable
                    records={developers}
                    removeRecord={handleRemoveRecord}
                    dataLoading={tableLoading}
                />
            </Stack>
        </Container>
    );
};

export default DeveloperListContainer;
