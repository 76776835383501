import { Navbar, NavLink, Tooltip } from "@mantine/core";
import {
    IconGauge,
    IconListDetails,
    IconCodeCircle,
    IconUsers,
} from "@tabler/icons-react";
import { Link, useLocation } from "react-router-dom";

interface CustomSideNavProps {
    opened: boolean;
}

const navItemList = [
    {
        icon: IconGauge,
        label: "Dashboard",
        link: "/dashboard",
        matchRegex: /^\/dashboard$/,
    },
    {
        icon: IconListDetails,
        label: "Customers",
        link: "/dashboard/customer-list",
        matches: [
            "/dashboard/customer-list",
            "/dashboard/settings",
            "/dashboard/referral-list",
        ],
    },
    {
        icon: IconCodeCircle,
        label: "Developers",
        link: "/dashboard/developer-list",
    },
    {
        icon: IconUsers,
        label: "Administrators",
        link: "/dashboard/administrators",
    },
];

const CustomSideNav = (props: CustomSideNavProps) => {
    const { opened } = props;

    const location = useLocation();

    const links = navItemList.map((item) => {
        const linkMatch = () => {
            const findMatch = item?.matches?.find((link: any) =>
                location.pathname.match(link)
            );

            if (item?.matches && findMatch) {
                return true;
            }

            return !!location.pathname.match(item?.matchRegex || item.link);
        };

        const renderLink = () => (
            <NavLink
                component={Link}
                label={opened ? item.label : null}
                icon={<item.icon size="1.5rem" stroke={1.5} />}
                active={linkMatch()}
                to={item.link}
                data-qa={item.label}
            />
        );

        return opened ? (
            renderLink()
        ) : (
            <Tooltip
                key={item.label}
                label={item.label}
                position="right"
                transitionProps={{ duration: 0 }}
            >
                {renderLink()}
            </Tooltip>
        );
    });

    return (
        <Navbar
            height="100%"
            width={opened ? { sm: 300 } : { sm: 82 }}
            p="md"
            hiddenBreakpoint="sm"
            hidden={!opened}
        >
            <Navbar.Section grow>{links}</Navbar.Section>
        </Navbar>
    );
};

export default CustomSideNav;
