import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import EnvUtil from "../utils/EnvUtil";
import { CONFIG } from "./ConfigFile";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

declare global {
    interface Window {
        FIREBASE_APPCHECK_DEBUG_TOKEN: string | boolean;
    }
}

const initBackend = (function () {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env
        .REACT_APP_APP_CHECK_DEBUG_TOKEN_FROM_CI as string;
    const env = EnvUtil.getEnv(window.location.href);
    const FIREBASE_APP = initializeApp(CONFIG[env]);
    const APP_CHECK = initializeAppCheck(FIREBASE_APP, {
        provider: new ReCaptchaV3Provider(
            "6LcLfHonAAAAAFzy9KWkIYMbosZgl5CUbTmxUKs8"
        ),
        isTokenAutoRefreshEnabled: true,
    });
    const DB = getDatabase(FIREBASE_APP);
    const FUNCTIONS = getFunctions(FIREBASE_APP, "europe-west2");
    const AUTH = getAuth(FIREBASE_APP);
    const FIRESTORE = getFirestore(FIREBASE_APP);
    const STORAGE = getStorage(FIREBASE_APP);
    if (process.env.REACT_APP_EMULATOR === "true") {
        connectFunctionsEmulator(FUNCTIONS, "127.0.0.1", 5001);
        connectAuthEmulator(AUTH, "http://127.0.0.1:9099");
        connectFirestoreEmulator(FIRESTORE, "127.0.0.1", 8080);
    }
    return {
        APP_CHECK,
        DB,
        FUNCTIONS,
        AUTH,
        FIRESTORE,
        STORAGE,
    };
})();

export default initBackend;
