import { ActionIcon, Box, Tooltip } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import MantineTable from "../../components/MantineTable";

interface DeveloperTableProps {
    records: any[];
    removeRecord: (recordId: string) => void;
    dataLoading: boolean;
}

const DeveloperTable = (props: DeveloperTableProps) => {
    const { records, removeRecord, dataLoading } = props;

    const columns = [
        {
            accessorKey: "name",
            header: "Name",
            sortingFn: "text",
        },
        {
            accessorKey: "email",
            header: "Email",
            sortingFn: "text",
        },
        {
            id: "customer",
            header: "Customer",
            accessorFn: (row: any) => row.customer_name,
            sortingFn: "text",
        },
        {
            accessorKey: "scope",
            header: "Scope",
            sortingFn: "text",
        },
    ];

    const renderActions = (row: any) => (
        <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
            <Tooltip label="Delete user" withArrow>
                <ActionIcon
                    color="red"
                    onClick={() => {
                        removeRecord(row.id);
                    }}
                >
                    <IconTrash size={20} />
                </ActionIcon>
            </Tooltip>
        </Box>
    );

    return (
        <MantineTable
            records={records}
            columns={columns}
            renderActions={renderActions}
            dataLoading={dataLoading}
        />
    );
};

export default DeveloperTable;
