import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Anchor, AnchorProps } from "@mantine/core";

interface FormattedLinkProps extends AnchorProps {
    href: string;
    target?: string;
    dataqa?: string;
}

const originUrl = (() => {
    if (window.location.origin) {
        return window.location.origin;
    }

    // fallback for IE
    return window.location.href.substring(
        0,
        window.location.href.indexOf(
            "/",
            window.location.href.indexOf(window.location.host)
        )
    );
})();

const isInternalLink = (url: string) => {
    return (
        url.startsWith(originUrl) ||
        url.startsWith("/") ||
        url.startsWith("https:///")
    );
};

function formatUrl(url: string) {
    if (!url) {
        return null;
    }

    if (url.startsWith("/")) {
        return url;
    }

    if (url.startsWith(originUrl)) {
        return url.substring(originUrl.length);
    }

    if (url.startsWith("https:///")) {
        return url.substring(8);
    }

    if (url.startsWith("https://")) {
        return url;
    }

    return `https://${url}`;
}

function FormattedLink(props: FormattedLinkProps) {
    const { children, href, target, dataqa } = props;

    // Open telephone and email links with the supported browser protocol
    // More info: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
    if (href.startsWith("tel:") || href.startsWith("mailto:")) {
        return (
            <Anchor
                {...props}
                href={href}
                data-qa={dataqa || "formattedLink-contactLink"}
            >
                {children}
            </Anchor>
        );
    }

    // internal link, so use react router dom
    if (isInternalLink(href)) {
        return (
            <Anchor
                {...props}
                component={RouterLink}
                to={formatUrl(href) || ""}
                target={target}
                data-qa={dataqa || "formattedLink-internalLink"}
            >
                {children}
            </Anchor>
        );
    }

    // open external link
    // more info on rel attribute: https://www.techwyse.com/blog/search-engine-optimization/what-you-need-to-know-about-rel-noreferrer-attribute/
    return (
        <Anchor
            {...props}
            href={formatUrl(href) || ""}
            target={target || "_blank"}
            rel="noopener noreferrer"
            data-qa={dataqa || "formattedLink-externalLink"}
        >
            {children}
        </Anchor>
    );
}

export default FormattedLink;
