import { HttpsCallableResult, httpsCallable } from "firebase/functions";
import backend from "../../config/DatabaseConfig";
import { Customer } from "../../store/AppSlice";
import { CallableResponse } from "./types";

/**
 * Adds a new customer to the platform
 * @param {Partial<Customer>} payload
 * @return {Promise<HttpsCallableResult<CallableResponse>>}
 */
export const createCustomer = (
    payload: Partial<Customer>
): Promise<HttpsCallableResult<CallableResponse>> => {
    return httpsCallable<any, CallableResponse>(
        backend.FUNCTIONS,
        "customers-createCustomer"
    )(payload);
};

/**
 *
 * @param {string} uid
 * @return {Promise<HttpsCallableResult<CallableResponse>>}
 */
export const deleteCustomer = ({
    id,
}: {
    id: string;
}): Promise<HttpsCallableResult<CallableResponse>> => {
    return httpsCallable<any, CallableResponse>(
        backend.FUNCTIONS,
        "customers-deleteCustomer"
    )({ id });
};
