import { HttpsCallableResult, httpsCallable } from "firebase/functions";
import backend from "../../config/DatabaseConfig";
import { CallableResponse } from "./types";

export type ResetPasswordPayload = {
    email: string;
};

/**
 * This will trigger a password reset email to the user if the email exists
 * @param payload - The payload to the send the reset link to if it exists
 * @return {Promise<HttpsCallableResult<CallableResponse>>}
 */
export const resetPassword = (
    payload: ResetPasswordPayload
): Promise<HttpsCallableResult<CallableResponse>> => {
    return httpsCallable<ResetPasswordPayload, CallableResponse>(
        backend.FUNCTIONS,
        "auth-resetUserPassword"
    )(payload);
};
