import {
    Timestamp,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { StateCreator } from "zustand";
import axios from "axios";
import backend from "../config/DatabaseConfig";
import {
    addDeveloperAccount,
    deleteDeveloperAccount,
} from "../services/callables/developers";
import {
    createCustomer,
    deleteCustomer as deleteCustomerService,
} from "../services/callables/customers";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

/**
 * @typedef {Object} Customer
 * @property {string} name
 * @property {string} email
 * @property {string} id
 * @property {Timestamp} created_at
 * @property {ApiDeveloper[]} developers
 * @property {Object} config
 */
export interface Customer {
    name: string;
    email: string;
    id: string;
    created_at: Timestamp;
    developers: ApiDeveloper[];
    config: { [field: string]: any };
    country: string;
    phone: string;
    active: boolean;
    chat_flow: string;
    sla_config: {
        link_active_duration: number;
    };
}

export interface ApiDeveloper {
    id: string;
    uid: string;
    name: string;
    email: string;
    createdAt: Timestamp;
}

export interface WebUiConfig {
    guidance_title?: string;
    guidance_content: string;
    faq_title: string;
    faq_content: string;
    logo_url?: string;
    terms_summary_id: string;
    full_terms_id: string;
    privacy_policy_id: string;
    faq_id: string;
    is_end_page_required: boolean;
    end_page_header: string;
    end_page_content: string;
}

export interface Referral {
    id: string;
    close_assessment_at: Timestamp;
    created_at: Timestamp;
    customer_id: string;
    customer_name: string;
    dob: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    referral_id: string;
    referral_type: "api" | "admin" | "self";
    referral_url: string;
    retrieved_on: Timestamp;
    status: "open" | "completed" | "closed";
    metadata: {
        address_line_1: string;
        address_line_2: string;
        allowance: number;
        post_code: string;
        uid: string;
    };
}

export interface AppSlice {
    getCustomers: () => Promise<Customer[] | undefined>;
    customers: Customer[];
    addCustomer: (payload: Partial<Customer>) => Promise<void>;
    isCustomerCreated: boolean;
    deleteCustomer: (uid: string) => Promise<void>;
    isCustomerDeleted: boolean;
    getCustomerById: (uid: string) => Promise<Customer | undefined>;
    currentCustomer: Customer | undefined;
    updateCustomer: (
        uid: string,
        values: Customer
    ) => Promise<Customer | undefined>;
    developers: ApiDeveloper[];
    isDeveloperCreated: boolean;
    getDevelopers: () => Promise<ApiDeveloper[] | undefined>;
    addDeveloper: (payload: Partial<ApiDeveloper>) => Promise<void>;
    deleteDeveloper: (uid: string) => Promise<void>;
    administrators: any[];
    getAdministrators: () => Promise<any[]>;
    webUiConfigData: WebUiConfig | undefined;
    getWebUiConfig: (uid: string) => Promise<WebUiConfig | undefined>;
    addOrUpdateWebUiConfig: (
        uid: string,
        values: Partial<WebUiConfig>
    ) => Promise<WebUiConfig | undefined>;
    uploadCustomerLogo: (imageUrl: File) => Promise<string>;
    chatFlows: any[];
    getChatFlows: () => Promise<any[]>;
    referrals: any[];
    getReferralsById: (uid: string) => Promise<Referral[] | undefined>;
    deleteReferral: (uid: string) => Promise<void>;
}

const initialAppState = {
    customers: [],
    currentCustomer: undefined,
    isCustomerCreated: false,
    isCustomerDeleted: false,
    isDeveloperCreated: false,
    developers: [],
    administrators: [],
    webUiConfigData: undefined,
    chatFlows: [],
    referrals: [],
};

export const createAppSlice: StateCreator<AppSlice> = (set, get) => ({
    ...initialAppState,
    addCustomer: async (payload: Partial<Customer>) => {
        try {
            const response = await createCustomer(payload);
            if (!response) return;
            set({ isCustomerCreated: true });
        } catch (e) {
            console.error(e);
        }
    },
    deleteCustomer: async (uid: string) => {
        try {
            const response = await deleteCustomerService({ id: uid });
            if (!response) return;
            set({ isCustomerDeleted: true });
        } catch (e) {
            console.error(e);
        }
    },
    getCustomers: async (): Promise<Customer[] | undefined> => {
        try {
            const db = backend.FIRESTORE;
            const ref = collection(db, "customers");
            const snapshot = await getDocs(ref);
            const customers: Customer[] = [];
            snapshot.forEach((doc) => {
                const customer = { ...doc.data(), id: doc.id } as Customer;
                customers.push(customer);
            });
            set({ customers });
            return customers;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    },
    getCustomerById: async (uid: string): Promise<Customer | undefined> => {
        try {
            const db = backend.FIRESTORE;
            const ref = doc(db, "customers", uid);
            const currentCustomer = await getDoc(ref);
            const currentCustomerData = {
                ...currentCustomer.data(),
                id: currentCustomer.id,
            } as Customer;
            set({ currentCustomer: currentCustomerData });
            return currentCustomerData;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    },
    updateCustomer: async (
        uid: string,
        values: Customer
    ): Promise<Customer | undefined> => {
        try {
            const db = backend.FIRESTORE;
            const ref = doc(db, "customers", uid);
            const document = await getDoc(ref);

            if (document.exists()) {
                await updateDoc(ref, {
                    ...values,
                    updated_at: serverTimestamp(),
                });
                return;
            }
        } catch (e) {
            console.error(e);
        }
    },
    getAdministrators: async (): Promise<any[]> => {
        try {
            const db = backend.FIRESTORE;
            const ref = collection(db, "admin_users");
            const snapshot = await getDocs(ref);
            const administrators: any[] = [];
            snapshot.forEach((doc) => {
                const administrator = { ...doc.data(), id: doc.id };
                administrators.push(administrator);
            });
            set({ administrators });
            return administrators;
        } catch (e) {
            console.error(e);
            return [];
        }
    },
    getWebUiConfig: async (uid: string): Promise<WebUiConfig | undefined> => {
        try {
            const db = backend.FIRESTORE;
            const ref = doc(db, "customers", uid, "web_ui_configs", uid);
            const config = await getDoc(ref);
            const configData = config.data() as WebUiConfig;
            set({ webUiConfigData: configData });
            return configData;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    },
    addOrUpdateWebUiConfig: async (
        uid: string,
        values: Partial<WebUiConfig>
    ): Promise<WebUiConfig | undefined> => {
        try {
            const db = backend.FIRESTORE;
            const ref = doc(db, "customers", uid, "web_ui_configs", uid);
            const config = await getDoc(ref);

            if (config.exists()) {
                await updateDoc(ref, {
                    ...values,
                    updated_at: serverTimestamp(),
                });
                return;
            }

            await setDoc(ref, { ...values, created_at: serverTimestamp() });
        } catch (e) {
            console.error(e);
        }
    },
    uploadCustomerLogo: async (image: File): Promise<string> => {
        const { currentCustomer } = get();
        const { STORAGE } = backend;

        if (!currentCustomer) {
            throw new Error("Current customer is undefined.");
        }

        const logoRef = ref(STORAGE, `customer_logos/${currentCustomer.id}`);

        const snapshot = await uploadBytes(logoRef, image);
        const logo_url = await getDownloadURL(snapshot.ref);

        return logo_url;
    },
    getChatFlows: async (): Promise<any[]> => {
        try {
            // TODO: We should move this to a callable function so that we can hide the API key
            const response = await axios.get(
                "https://eql-bot-api.web.app/bot-api/v1/bot.list",
                {
                    headers: {
                        "x-api-key": "e1e90afc-9887-4370-a72d-59aaa2f8a9fa",
                    },
                }
            );
            if (!response) return [];
            const chatFlows = response.data;
            const chatFlowData = chatFlows.result.data;
            set({ chatFlows: chatFlowData });
            return chatFlowData;
        } catch (e) {
            console.error(e);
            return [];
        }
    },
    getReferralsById: async (uid: string): Promise<Referral[]> => {
        try {
            const db = backend.FIRESTORE;
            const ref = collection(db, "referrals");
            const clientRef = query(ref, where("customer_id", "==", uid));
            const snapshot = await getDocs(clientRef);
            const referrals: Referral[] = [];
            snapshot.forEach((doc) => {
                const referral = {
                    ...doc.data(),
                    id: doc.id,
                } as unknown as Referral;
                referrals.push(referral);
            });
            if (!referrals) return [];

            set({ referrals });
            return referrals;
        } catch (e) {
            console.error(e);
            return [];
        }
    },
    deleteReferral: async (uid: string) => {
        try {
            const db = backend.FIRESTORE;
            const ref = doc(db, "referrals", uid);
            await deleteDoc(ref);
            return;
        } catch (e) {
            console.error(e);
        }
    },
    getDevelopers: async (): Promise<ApiDeveloper[]> => {
        try {
            const db = backend.FIRESTORE;
            const ref = collection(db, "developers");
            const snapshot = await getDocs(ref);
            const developers: ApiDeveloper[] = [];
            snapshot.forEach((doc) => {
                const developer = { ...doc.data(), id: doc.id } as ApiDeveloper;
                developers.push(developer);
            });
            set({ developers });
            return developers;
        } catch (e) {
            console.error(e);
            return [];
        }
    },
    addDeveloper: async (payload: Partial<ApiDeveloper>) => {
        try {
            const response = await addDeveloperAccount(payload);
            if (!response) return;
            set({ isDeveloperCreated: true });
        } catch (e) {
            console.error(e);
        }
    },
    deleteDeveloper: async (uid: string) => {
        try {
            await deleteDeveloperAccount({ developerId: uid });
            return;
        } catch (e) {
            console.error(e);
        }
    },
});
