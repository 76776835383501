import { Stack, TextInput, Textarea } from "@mantine/core";
import { useSettingsFormContext } from "./SettingsForm";

const SidebarForm = () => {
    const form = useSettingsFormContext();

    return (
        <Stack>
            <TextInput
                label="Guidance Section Title"
                placeholder="Type title"
                radius="md"
                {...form.getInputProps("guidanceTitle")}
                data-qa="customerSettings_guidanceTitleField"
            />

            <Textarea
                label="Guidance Section Content"
                placeholder="Type content"
                radius="md"
                withAsterisk
                {...form.getInputProps("guidanceContent")}
                autosize
                minRows={4}
                maxRows={8}
                data-qa="customerSettings_guidanceContentField"
            />

            <TextInput
                label="FAQ Section Title"
                placeholder="Type title"
                radius="md"
                withAsterisk
                {...form.getInputProps("faqTitle")}
                data-qa="customerSettings_faqTitleField"
            />

            <Textarea
                label="FAQ Section Content"
                placeholder="Type content"
                radius="md"
                withAsterisk
                {...form.getInputProps("faqContent")}
                autosize
                minRows={4}
                maxRows={8}
                data-qa="customerSettings_faqContentField"
            />
        </Stack>
    );
};

export default SidebarForm;
