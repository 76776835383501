import { useEffect } from "react";
import { Title, Space, LoadingOverlay, Text } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { useDisclosure, useSetState } from "@mantine/hooks";
import { useBoundStore } from "../../store";
import { Customer, WebUiConfig } from "../../store/AppSlice";
import SettingsForm, { FormValues } from "./SettingsForm";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import sanitizeText from "../../utils/sanitizeText";

interface CustomerState {
    loading: boolean;
    isDataLoaded: boolean;
}

const CustomerSettingsContainer = () => {
    const [{ loading, isDataLoaded }, setState] = useSetState<CustomerState>({
        loading: false,
        isDataLoaded: false,
    });

    const [visible, { close: closeOverlay, open: openOverlay }] =
        useDisclosure(false);

    const {
        getCustomerById,
        currentCustomer,
        getWebUiConfig,
        addOrUpdateWebUiConfig,
        getChatFlows,
        chatFlows,
        updateCustomer,
        webUiConfigData,
        uploadCustomerLogo,
    } = useBoundStore();

    const navigate = useNavigate();

    const params = useParams();
    const { id } = params;

    const handleSubmit = async (values: FormValues): Promise<void> => {
        try {
            setState({ loading: true });

            const {
                email,
                name,
                phone,
                country,
                active,
                guidanceTitle,
                guidanceContent,
                faqTitle,
                faqContent,
                logo,
                chatFlow,
                linkActiveDuration,
                termsSummaryId,
                fullTermsId,
                privacyPolicyId,
                faqId,
                isEndPageRequired,
                endPageHeader,
                endPageContent,
            } = values;

            if (!id) return;

            const logoUrl = logo && (await uploadCustomerLogo(logo));

            await updateCustomer(id, {
                email,
                name,
                phone,
                country,
                active,
                chat_flow: chatFlow,
                sla_config: {
                    link_active_duration: Number(linkActiveDuration),
                },
            } as Customer);

            const logoValue = logo && { logo_url: logoUrl };

            const sanitizedContent =
                endPageContent !== "<p></p>"
                    ? sanitizeText(endPageContent)
                    : "";

            await addOrUpdateWebUiConfig(id, {
                guidance_title: guidanceTitle,
                guidance_content: guidanceContent,
                faq_title: faqTitle,
                faq_content: faqContent,
                terms_summary_id: termsSummaryId,
                full_terms_id: fullTermsId,
                privacy_policy_id: privacyPolicyId,
                faq_id: faqId,
                is_end_page_required: isEndPageRequired,
                end_page_header: endPageHeader,
                end_page_content: sanitizedContent,
                ...logoValue,
            } as WebUiConfig);

            notifications.show({
                title: "Success!",
                message: `Customer settings successfully updated`,
                color: "green",
                icon: <IconCheck />,
            });

            navigate("/dashboard/customer-list");
        } catch (e) {
            console.log(e);
            notifications.show({
                title: "Action failed",
                message: "Something went wrong, please try again",
                color: "red",
                icon: <IconX />,
            });
        } finally {
            setState({ loading: false });
        }
    };

    const onSubmitConfirmation = (values: FormValues) => {
        modals.openConfirmModal({
            id: "submitSettings",
            title: "Submit Changes",
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to update settings for this customer?
                </Text>
            ),
            onCancel: () => modals.close("submitSettings"),
            onConfirm: () => handleSubmit(values),
        });
    };

    useEffect(() => {
        (async () => {
            try {
                if (!id) return;

                setState({ isDataLoaded: false });
                openOverlay();
                await getCustomerById(id);
                await getWebUiConfig(id);
                await getChatFlows();
            } catch (e) {
                console.log(e);
            } finally {
                setState({ isDataLoaded: true });
                closeOverlay();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Title order={1} size="h1">
                Customer Settings
            </Title>

            <Space h="lg" />
            {isDataLoaded && (
                <SettingsForm
                    customer={currentCustomer}
                    submitting={loading}
                    onSubmit={onSubmitConfirmation}
                    webUiConfig={webUiConfigData}
                    chatFlowsData={chatFlows}
                />
            )}
            <LoadingOverlay visible={visible} overlayBlur={2} />
        </>
    );
};

export default CustomerSettingsContainer;
