import { useEffect } from "react";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import initBackend from "../../config/DatabaseConfig";
import { useSetState } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import { LoadingOverlay } from "@mantine/core";
import { useBoundStore } from "../../store";

const ResetPasswordContainer = () => {
    const [state, setState] = useSetState({
        loading: false,
        pageLoading: true,
        resetCodeVerified: false,
        verifiedEmail: "",
    });

    const { login, logout, isAuthenticated, user } = useBoundStore();

    const navigate = useNavigate();

    let [searchParams] = useSearchParams();
    const token = searchParams.get("oobCode");

    const auth = initBackend.AUTH;

    useEffect(() => {
        (async () => {
            try {
                if (!token) {
                    return;
                }

                const accountEmail = await verifyPasswordResetCode(auth, token);

                if (!accountEmail) {
                    setState({ resetCodeVerified: false });
                    return;
                }

                setState({
                    resetCodeVerified: true,
                    verifiedEmail: accountEmail,
                });
            } catch (e) {
                console.error(e);
            } finally {
                setState({ pageLoading: false });
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const handleOnSubmit = async (password: string) => {
        try {
            if (!token) {
                return;
            }

            setState({ loading: true });

            await confirmPasswordReset(auth, token, password);
            await logout();
            await login(state.verifiedEmail, password);
        } catch (e) {
            const error = e as Error;
            console.error(e);
            notifications.show({
                title: "Error!",
                message: error.message,
                color: "red",
                icon: <IconX />,
            });
        } finally {
            setState({ loading: false });
        }
    };

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        navigate("/dashboard", { replace: true });

        notifications.show({
            title: "Success!",
            message: `You've been logged in as ${user?.displayName} `,
            color: "green",
            icon: <IconCheck />,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <>
            <LoadingOverlay visible={state.pageLoading} overlayBlur={2} />
            <ResetPassword
                onSubmit={handleOnSubmit}
                loading={state.loading}
                isVerified={!!token && state.resetCodeVerified}
            />
        </>
    );
};

export default ResetPasswordContainer;
