import { HttpsCallableResult, httpsCallable } from "firebase/functions";
import backend from "../../config/DatabaseConfig";
import { CallableResponse } from "./types";

export interface AdminUser {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
}

/**
 * Adds a new admin user to the platform
 * @param {AdminUser} user - The user to be added
 * @return {Promise<HttpsCallableResult<CallableResponse>>}
 */
export const addAdminUser = (
    user: AdminUser
): Promise<HttpsCallableResult<CallableResponse>> => {
    return httpsCallable<AdminUser, CallableResponse>(
        backend.FUNCTIONS,
        "auth-createNewAdminUser"
    )(user);
};

/**
 *
 * @param {string} uid
 * @return {Promise<HttpsCallableResult<CallableResponse>>}
 */
export const deleteAdminUser = (
    uid: string
): Promise<HttpsCallableResult<CallableResponse>> => {
    return httpsCallable<string, CallableResponse>(
        backend.FUNCTIONS,
        "auth-deleteAdminUser"
    )(uid);
};
