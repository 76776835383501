import { ActionIcon, Box, Tooltip } from "@mantine/core";
import {
    IconCheck,
    IconSettings,
    IconTrash,
    IconUsers,
    IconX,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { Customer } from "../../store/AppSlice";
import FormattedLink from "../../components/FormattedLink";
import MantineTable from "../../components/MantineTable";

interface CustomerTableProps {
    records: Customer[];
    removeRecord: (recordId: string) => void;
    dataLoading: boolean;
    chatFlows: any[];
}

const CustomerTable = (props: CustomerTableProps) => {
    const { records, removeRecord, dataLoading, chatFlows } = props;

    const columns = [
        { accessorKey: "name", header: "Customer Name", sortingFn: "text" },
        { accessorKey: "email", header: "Customer Email", sortingFn: "text" },
        { accessorKey: "phone", header: "Customer Phone", sortingFn: "text" },
        {
            id: "chat_flow",
            header: "Chat Flow",
            accessorFn: (row: Customer) => {
                if (!row.chat_flow) return null;

                return chatFlows?.find((flow) => flow.id === row.chat_flow)
                    ?.name;
            },
            sortingFn: "text",
        },
        {
            accessorKey: "active",
            header: "Active",
            mantineTableBodyCellProps: {
                align: "center",
            },
            size: 80,
            Cell: ({ row }: any) => {
                if (row.original.active) return <IconCheck color="teal" />;
                if (row.original.active === false) return <IconX color="red" />;
                return null;
            },
        },
        {
            id: "created_at",
            header: "Created on",
            size: 120,
            accessorFn: (row: Customer) => {
                if (!row.created_at) return null;
                const date = dayjs.unix(row.created_at.seconds);
                return date;
            },
            Cell: ({ cell }: any) =>
                dayjs(cell.getValue()).format("DD/MM/YYYY"),
            sortingFn: "datetime",
        },
    ];

    const renderActions = (row: any) => (
        <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
            <Tooltip label="Delete customer" withArrow>
                <ActionIcon
                    color="red"
                    onClick={() => {
                        removeRecord(row.id);
                    }}
                    data-qa="customer_removeButton"
                >
                    <IconTrash size={20} />
                </ActionIcon>
            </Tooltip>

            <Tooltip label="Go to configuration page" withArrow inline>
                <div>
                    <ActionIcon
                        color="blue"
                        component={FormattedLink}
                        href={`/dashboard/settings/${row.id}`}
                        dataqa="customer_editButton"
                    >
                        <IconSettings size={20} />
                    </ActionIcon>
                </div>
            </Tooltip>

            <Tooltip label="Go to customer’s referral list" withArrow>
                <div>
                    <ActionIcon
                        color="blue"
                        component={FormattedLink}
                        href={`/dashboard/referral-list/${row.id}`}
                    >
                        <IconUsers size={20} />
                    </ActionIcon>
                </div>
            </Tooltip>
        </Box>
    );

    return (
        <MantineTable
            records={records}
            columns={columns}
            renderActions={renderActions}
            dataLoading={dataLoading}
            sorting={[
                {
                    id: "created_at",
                    desc: true,
                },
            ]}
        />
    );
};

export default CustomerTable;
