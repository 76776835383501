import {
    User,
    multiFactor,
    RecaptchaVerifier,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    MultiFactorError,
    MultiFactorResolver,
    getMultiFactorResolver,
    PhoneInfoOptions,
    UserCredential,
} from "firebase/auth";
import parsePhoneNumber from "libphonenumber-js";
import backend from "../../config/DatabaseConfig";

// Create a RecaptchaVerifier
// recaptcha-container-id is the ID of an element in the DOM, this can be found in public/index.html
const recaptchaVerifier = new RecaptchaVerifier(
    backend.AUTH,
    "recaptcha-container-id",
    {
        size: "invisible",
    }
);

/**
 * @remarks - This function sends a verification code and returns the verification code ID
 * @param phoneNumber - The phone number to send the verification code to
 * @param user - The user to send the verification code to
 * @returns The verification code ID
 * @throws
 */
export const verifyProvidedPhoneNumberForUser = async (
    phoneNumber: string,
    user: User
): Promise<string> => {
    const validPhoneNumber = parsePhoneNumber(phoneNumber, "GB");
    if (!validPhoneNumber) {
        throw new Error("Invalid phone number");
    }
    const multiFactorSession = await multiFactor(user).getSession();
    const phoneInfoOptions: PhoneInfoOptions = {
        phoneNumber: validPhoneNumber.number,
        session: multiFactorSession,
    };
    const phoneAuthProvider = new PhoneAuthProvider(backend.AUTH);
    const verificationCodeId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier
    );
    return verificationCodeId;
};

/**
 *
 * @param user
 * @param verificationCodeId
 * @param verificationCode
 * @returns {Promise<void>}
 */
export const verify2FAcodeForUser = async (
    user: User,
    verificationCodeId: string,
    verificationCode: string
): Promise<void> => {
    // Ask user for the verification code. Then:
    const cred = PhoneAuthProvider.credential(
        verificationCodeId,
        verificationCode
    );
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    // Complete enrollment.
    await multiFactor(user).enroll(
        multiFactorAssertion,
        "personal_phone_number"
    );
};

/**
 * Resolves the multifactor login error and returns the verification code ID and resolver
 * @param {MultiFactorError} error - The multifactor error
 * @returns {Promise<{verificationCodeId: string; resolver: MultiFactorResolver}>}
 */
export const resolveMultifactorLogin = async (
    error: MultiFactorError
): Promise<{ verificationCodeId: string; resolver: MultiFactorResolver }> => {
    // Get the MultiFactorResolver from the error
    const resolver: MultiFactorResolver = getMultiFactorResolver(
        backend.AUTH,
        error
    );
    const hints = resolver.hints;
    const selectedHint = hints[0]; // Choose the first enrolled factor

    // Prepare the phone authentication options
    const phoneInfoOptions: PhoneInfoOptions = {
        multiFactorHint: selectedHint,
        session: resolver.session,
    };

    const phoneAuthProvider = new PhoneAuthProvider(backend.AUTH);
    const verificationCodeId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier
    );
    return { verificationCodeId, resolver };
};

/**
 *
 * @param resolver
 * @param verificationCodeId
 * @param verificationCode
 * @returns
 */
export const signInWithMultifactorResolver = async (
    resolver: MultiFactorResolver,
    verificationCodeId: string,
    verificationCode: string
): Promise<UserCredential> => {
    const cred = PhoneAuthProvider.credential(
        verificationCodeId,
        verificationCode
    );
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    // login with the resolver
    const user = await resolver.resolveSignIn(multiFactorAssertion);
    return user;
};
/**
 * Checks if multifactor authentication is enabled for a user
 * @param user
 * @returns {boolean}
 */
export const isMfaEnabled = (user: User): boolean => {
    const factors = multiFactor(user).enrolledFactors;
    if (factors.length > 0) {
        return true;
    }
    return false;
};
