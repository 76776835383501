import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../layouts/RootLayout";
import DashboardLayout from "../layouts/DashboardLayout";
import AuthLayout from "../layouts/AuthLayout";
import NotFoundView from "../views/NotFoundView";
import ForgotPasswordContainer from "../containers/forgotPassword/ForgotPasswordContainer";
import ResetPasswordContainer from "../containers/resetPassword/ResetPasswordContainer";
import ManagementInformationContainer from "../containers/ManagementInformationContainer";
import AuthRequired from "./AuthRequired";
import CustomerListContainer from "../containers/customers/CustomerListContainer";
import DeveloperListContainer from "../containers/developers/DeveloperListContainer";
import ReferralsContainer from "../containers/referrals/ReferralsContainer";
import AdminUserListContainer from "../containers/adminUsers/AdminUserListContainer";
import CustomerSettingsContainer from "../containers/settings/CustomerSettingsContainer";
import Enrollment from "../containers/multifactor/Enrollment";

const router = createBrowserRouter([
    {
        path: "/",
        element: <RootLayout />,
    },
    { path: "/login", element: <AuthLayout /> },
    { path: "forgot-password", element: <ForgotPasswordContainer /> },
    { path: "reset-password", element: <ResetPasswordContainer /> },
    { path: "2fa-enrollment", element: <Enrollment /> },
    {
        element: (
            <AuthRequired>
                <DashboardLayout />
            </AuthRequired>
        ),
        path: "dashboard",
        children: [
            {
                index: true,
                element: <ManagementInformationContainer />,
            },
            {
                path: "referral-list/:id",
                element: <ReferralsContainer />,
            },
            {
                path: "customer-list",
                element: <CustomerListContainer />,
            },
            {
                path: "settings/:id",
                element: <CustomerSettingsContainer />,
            },
            {
                path: "developer-list",
                element: <DeveloperListContainer />,
            },
            {
                path: "administrators",
                element: <AdminUserListContainer />,
            },
        ],
    },
    { path: "*", element: <NotFoundView /> },
]);

export default router;
