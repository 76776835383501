import { Flex, Input, Select, Text, createStyles } from "@mantine/core";
import { useId } from "@mantine/hooks";
import { forwardRef } from "react";
import {
    FlagEmoji,
    usePhoneInput,
    defaultCountries,
    parseCountry,
} from "react-international-phone";

type ItemProps = {
    country: any;
};

const useStyles = createStyles(() => ({
    dropdown: {
        minWidth: "250px",
        left: "16px !important",
    },
    input: {
        width: 0,
    },
}));

const PhoneField = (props: any) => {
    const { value, onChange } = props;
    const id = useId();

    const { classes } = useStyles();

    const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
        usePhoneInput({
            defaultCountry: "gb",
            value,
            countries: defaultCountries,
            onChange: (data) => {
                onChange(data.phone, data.country);
            },
            forceDialCode: true,
        });

    const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
        ({ country, ...others }: ItemProps, ref) => (
            <Flex ref={ref} {...others}>
                <FlagEmoji iso2={country.iso2} />
                <Text mx="8px">{country.name}</Text>
                <Text>+{country.dialCode}</Text>
            </Flex>
        )
    );

    return (
        <Input.Wrapper
            id={id}
            label={props.label}
            required={props.required}
            error={props.error}
        >
            <Flex>
                <Select
                    value={country}
                    onChange={setCountry}
                    itemComponent={SelectItem}
                    icon={<FlagEmoji iso2={country || "gb"} />}
                    data={defaultCountries.map((c) => {
                        const country = parseCountry(c);
                        return {
                            value: country.iso2,
                            label: "",
                            country: country,
                        };
                    })}
                    classNames={{
                        dropdown: classes.dropdown,
                        input: classes.input,
                    }}
                    radius="md"
                    mr="10px"
                />

                <Input
                    {...props}
                    value={phone}
                    placeholder="Phone number"
                    type="tel"
                    color="primary"
                    onChange={handlePhoneValueChange}
                    ref={inputRef}
                    sx={{ width: "100%" }}
                    data-qa="phoneField"
                />
            </Flex>
        </Input.Wrapper>
    );
};

export default PhoneField;
